import { environment as envPROD } from './environment.prod';
import { Environment, StandaloneType } from './environment.type';

const domain = 'https://myvete-srs.royalcanin.com';

export const environment: Environment = {
  ...envPROD,
  isStandalone: true,
  standaloneType: StandaloneType.MYVETE,
  // https://github.com/angular/angular-cli/issues/19622
  graphColor: 'rgb(157, 34, 202)',
  marsFooterScript: 'https://footer.mars.com/js/footer.js.aspx?SRS-MyVete',
  oneTrustId: '0190bc4c-d74a-7339-af56-22850b205c37',
  ciam: {
    ...envPROD.ciam,
    redirectCallback: domain,
  },
  oktaConfig: {
    issuer: 'https://signin.royalcanin.com/oauth2/default',
    clientId: '0oaer7d58mP8kdsql417',
    redirectUri: `${domain}/callback`,
    postLogoutRedirectUri: domain,
    scopes: ['openid', 'groups'],
    pkce: true,
    tokenManager: {
      autoRenew: true,
      autoRemove: true,
    },
  },
};
