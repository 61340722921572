import { Env } from '@app/shared/utils/enums/environment.enum';
import { Environment } from './environment.type';

export const domain = 'https://vet.royalcanin.com';

export const environment: Environment = {
  envName: Env.PRD,
  production: true,
  isStandalone: false,
  scriptEnv: 'VetFoundation',
  bffApiUrl: `https://prd-eus1-rc-vet-vet-bff.cloud-effem.com`,
  productApiUrl: 'https://prd-eus2-rcapi-apim.azure-api.net/internal/product',
  vetApiUrl: 'https://prd-eus2-rcapi-apim.azure-api.net/internal/vet',
  clinicApiUrl: 'https://prd-eus2-rcapi-apim.azure-api.net/internal/clinic',
  toolApiUrl: 'https://prd-eus2-rcapi-apim.azure-api.net/internal/tool',
  nutritionApiUrl: 'https://prd-eus2-rcapi-apim.azure-api.net/internal/nutrition',
  healthNutritionApiUrl: 'https://prd-eus2-rcapi-apim.azure-api.net/internal/health-nutrition',
  petOwnersApiUrl: 'https://prd-eus2-rcapi-apim.azure-api.net/internal/petOwner',
  coreApiUrl: 'https://prd-eus2-rcapi-apim.azure-api.net/internal/core',
  myAccountUrl: 'https://account.royalcanin.com',
  ciam: {
    apiUrl: 'https://ciamapi.royalcanin.com',
    app: 'https://welcome.royalcanin.com',
    redirectCallback: domain,
  },
  blobUrl: `https://prdeus2rcvetfileblob.blob.core.windows.net/configuration`,
  blobQueryParams:
    'sp=r&st=2022-12-14T14:53:08Z&se=2026-04-02T21:53:08Z&spr=https&sv=2021-06-08&sr=c&sig=AnmOMqxjr9jbnphApEfW5CXISO8ZkQoSyih2Hlmmd5A%3D',
  blobApiUrl: `https://prdeus2rcapivetblob.blob.core.windows.net`,
  apimSubscriptionKey: '40eb7a1687a247b38b67d65a46a17e3f',
  appInsights: {
    instrumentationKey: 'd89da4ed-8923-437a-ab62-ee10a345abca',
    role: 'vet-foundation-webapp',
    roleInstance: 'eus2-vet-foundation-webapp',
  },
  oktaConfig: {
    issuer: 'https://signin.royalcanin.com/oauth2/default',
    clientId: '0oa2icik6hDOgAQsX416',
    redirectUri: `${domain}/callback`,
    postLogoutRedirectUri: domain,
    pkce: true,
    maxClockSkew: 900,
    scopes: [
      'groups',
      'openid',
      'profile',
      'email',
      'user.profile:write',
      'user.consent:read',
      'purposes:read',
      'user.consent:collect',
      'user.consent:delete',
      'pets:read',
      'pets:write',
      'companies:read',
      'relations:write',
      'relations:read',
    ],
    tokenManager: {
      autoRenew: true,
      autoRemove: true,
    },
  },
  marsFooterScript: null,
  oneTrustId: '0643c84e-3a70-42c5-b752-f1611aa19742',
  fgsUrl: `https://shop.royalcanin.de/recommendation`,
  individualisUrl: `https://vet.individualis.com`,
  faroConfig: {
    enabled: true,
    url: 'https://grafana-agent.monitoring.global.rc-df-aks.cloud-effem.com',
    apiKey: 'ZjRyMEFwNmRmSGU2-NDJO-anhjOTBMNjc2YnZj-SWRH-VnFBUVFw-TU5CalVFQjY0M0d5-dGVPU',
  },
};
